import "./index.scss";

import React from "react";
import Select from  "react-select"

const CustomOption = (props) => {
    const { data, innerRef, isSelected, innerProps } = props;

    return (
      <div ref={innerRef} {...innerProps} className={`dropdown__custom-option ${isSelected ? "dropdown__custom-option--is-selected" : ""}`}>
        <img src={data.image} alt={data.label} className="dropdown__custom-option-img" />
        <span className="dropdown__custom-option-name">{data.label}</span>
      </div>
    );
};

const CustomSingleValue = ({ data }) => (
    <div className="dropdown__custom-single-value">
      <img src={data.image} alt={data.label} className="dropdown__custom-option-img"/>
      <span className="dropdown__custom-option-name">{data.label}</span>
    </div>
);

const Dropdown = ({options, defaultOption, onChange}) => (
    <Select
        options={options}
        placeholder="Select token..."
        className="dropdown"
        classNamePrefix="dropdown"
        components={{Option: CustomOption, SingleValue: CustomSingleValue}}
        value={defaultOption}
        onChange={onChange}
    />
)

export default Dropdown