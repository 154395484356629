const PROFILE_TABS = [
  { title: "Download App", name: "download" },
  { title: "Available plans", name: "plans" },
  { title: "Billing History", name: "billing" },
  { title: "Subscriptions", name: "subscriptions" },
  //  { title: "Reports", name: "reports" },
  { title: "Referral", name: "referral" },
  { title: "Settings", name: "settings" },
];

const FEEDBACKS_DATA = [
  {
    username: "Arish Hurbans",
    title: "Solid performance.",
    text: "VPN performance has been solid. I'm able to browse Netflix catalogs that are not available in my country and not have any issues with stream performance. Highly rated.",
    link: "https://www.trustpilot.com/reviews/66f3a4739bf06a3d36f6b936",
    rate: 5,
  },
  {
    username: "Halenry V",
    title: "It makes using a VPN easy",
    text: "It makes using a VPN easy. There are tons of selections and the connections are incredibly fast. I'll be using this VPN from now on.",
    link: "https://www.trustpilot.com/reviews/66f384086c8bf93fd0538802",
    rate: 5,
  },
  {
    username: "Oli",
    title: "Easy to use",
    text: "Easy to use! Fast, secure and worth it. Available in the App-Store for IPhone, also Android or on PC. Great Product - absolutely recommend it 👍",
    link: "https://www.trustpilot.com/reviews/66f3cca91aa6b1f3c6f2082a",
    rate: 5,
  },
  {
    username: "Dino H.",
    title: "I've been using VPN4U for a few weeks…",
    text: "I've been using VPN4U for a few weeks now and it's a blast (fast, easy to use) and I think I've found my new VPN service!",
    link: "https://www.trustpilot.com/reviews/66f3b890a057edac4c11842c",
    rate: 5,
  },
  {
    username: "Indigo Coquelicot",
    title: "A great service!",
    text: "Fast, simple and safe. Wouldn't use another!",
    link: "https://www.trustpilot.com/reviews/66f3627826cb49bf94a04a34",
    rate: 5,
  },
  {
    username: "tam hewitt-baker",
    title: "Simple to use with lot's of server…",
    text: "Simple to use with lot's of server options.",
    link: "https://www.trustpilot.com/reviews/66f3bac6158339474cae4d24",
    rate: 5,
  },
  {
    username: "Mark Smith",
    title: "Very solid service",
    text: "Very solid service! Happy so far.",
    link: "https://www.trustpilot.com/reviews/66f360fc4bad4157f0e34180",
    rate: 5,
  },
  {
    username: "Shoakrom",
    title: "Confident with my security",
    text: "It is really easy to use vpn, Since I started using this vpn, I am pretty confident with my security stuff.",
    link: "https://www.trustpilot.com/reviews/66cdee007e67b103acc94df8",
    rate: 5,
  },
  {
    username: "Joost Zagt",
    title: "Easy to use",
    text: "Easy to use! Works smooth!",
    link: "https://www.trustpilot.com/reviews/66f3c9b2e51e345b6d4240da",
    rate: 5,
  },
  {
    username: "Jonathan Angka",
    title: "Just so easy to use",
    text: "",
    link: "https://www.trustpilot.com/reviews/66f37207b7b8fcdbe56f105f",
    rate: 5,
  },
  {
    username: "Vasyl Zahorodniuk",
    title: "VPN4U - best VPN for IOS",
    text: "Great product! Very fast servers - I use IOS version.",
    link: "https://www.trustpilot.com/reviews/66be6f99043c3b8a4aa17851",
    rate: 5,
  },
];

const REFERRAL_TERMS = {
  first: "90",
  second: "50",
  standard: "30",
};

const SCRIPTS = {
  googleTagSuccessPayment: `
      gtag('event', 'conversion', {
          'send_to': 'AW-16685472120/nh3YCKO4h84ZEPiyoJQ-',
          'value': 1.0,
          'currency': 'USD',
          'transaction_id': ''
      });
    `,
  twitterConversation: `
        twq('event', 'tw-onud8-onuda', {});
`,
};

const SCRIPT_TYPES = {
  googleTagSuccessPayment: "googleTagSuccessPayment",
  //twitterConversation: "twitterConversation",
};

export { PROFILE_TABS, FEEDBACKS_DATA, REFERRAL_TERMS, SCRIPTS, SCRIPT_TYPES };
