import "./index.scss";

import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import map from "../../../assets/worldMap.png";
import ReviewStarImg from "../../../assets/review-star.svg";

import Slider from "react-slick";

import { REFERRAL_TERMS } from "../../../const/general";

const NextArrow = ({ style, onClick }) => (
  <div
    className="arrowNext"
    onClick={onClick}
    style={{
      ...style,
    }}
  />
);

const PrevArrow = ({ style, onClick }) => (
  <div
    className="arrowPrev"
    style={{
      ...style,
    }}
    onClick={onClick}
  />
);

const CustomSlide = ({ feedbackData }) => {
  const { username, text, title, link, rate } = feedbackData;

  return (
    <div className="feedbacks_card">
      <p className="name">{username}</p>
      <div className="feedbacks_card-stars">
        {[...new Array(rate)].map((_, i) => (
          <img
            src={ReviewStarImg}
            className="feedbacks_card-star"
            alt="star"
            key={i}
          />
        ))}
      </div>
      <p className="feedbacks_card-head">
        <a href={link} target="_blank" rel="noreferrer">
          {title}
        </a>
      </p>
      <p className="feedback">{text}</p>
    </div>
  );
};

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 600,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const CustomersView = ({
  handleSelectPlan,
  ONE_YEAR_TRIAL_PLAN,
  referralInfo,
  feedbacksData,
  goToProfile,
  slidesToShow,
  containerRef,
  mapRef,
  titleRef,
  desc1Ref,
  desc2Ref,
  btnRef,
}) => (
  <>
    <section className="world_section">
      <h3 className="world_title">Our servers around the world</h3>
      <p className="world_subtitle">The whole world is open to you</p>
      <div className="world_wrapper" ref={containerRef}>
        <img src={map} alt="map" className="world_map" ref={mapRef} />
      </div>
    </section>
    <section className="feedbacks_section">
      <h3 className="feedbacks_title">
        Our customer network is growing every day
      </h3>
      <div className="feedbacks_slider" id="reviews">
        <Slider {...sliderSettings} slidesToShow={slidesToShow}>
          {feedbacksData.map((feedback, i) => (
            <CustomSlide index={i} feedbackData={feedback} key={i} />
          ))}
        </Slider>
      </div>

      <div className="freeTrial_block">
        <h3 className="freeTrial_title" ref={titleRef}>
          Try our product for free
        </h3>
        <p className="freeTrial_desc" ref={desc1Ref}>
          Still haven't decided on a VPN?
        </p>
        <p className="freeTrial_desc" ref={desc2Ref}>
          Get a 72-hour trial!
        </p>
        <button
          className="secondary-button freeTrial_btn"
          ref={btnRef}
          onClick={() => handleSelectPlan(ONE_YEAR_TRIAL_PLAN, "freeTrial")}
        >
          Get Free Trial
        </button>
      </div>
    </section>
    <section className="home-referral" id="referral">
      <h3>Referral program</h3>
      <p className="home-referral__main-desc" ref={desc1Ref}>
        Earn passive income with a referral program
      </p>
      <div>
        <p className="home-referral__subtitle">
          You will receive a share of the funds from each purchase of your
          <br />
          &nbsp; referrals. What you get:
        </p>
        <div className="home-referral__cards">
          <div className="home-referral__card">
            <p>
              Your referral's first
              <br /> purchase
            </p>
            <h1>
              {referralInfo
                ? referralInfo.first_purchase_rate
                : REFERRAL_TERMS.first}
              %
            </h1>
          </div>
          <div className="home-referral__card">
            <p>
              Your referral's second
              <br /> purchase
            </p>
            <h1>
              {referralInfo
                ? referralInfo.second_purchase_rate
                : REFERRAL_TERMS.second}
              %
            </h1>
          </div>
          <div className="home-referral__card">
            <p>
              Your referral's third and
              <br /> subsequent purchases
            </p>
            <h1>
              {referralInfo
                ? referralInfo.constant_purchase_rate
                : REFERRAL_TERMS.standard}
              %
            </h1>
          </div>
        </div>
        <div className="home-referral__account">
          <p>
            Get a unique referral link and code, as well as materials for their
            promotion
            <br /> in your personal account and start earning passive income.
          </p>
          <button className="default-button" onClick={goToProfile}>
            Personal account
          </button>
        </div>
      </div>
    </section>
  </>
);

export default CustomersView;
