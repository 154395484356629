import "./index.scss";

import { Oval, RotatingLines } from "react-loader-spinner";

const Loader = ({ color, secondaryColor, cls }) => {
  return (
    <Oval
      color={color ?? "#fff"}
      wrapperStyle={{}}
      wrapperClass={cls ?? "loader__spinner"}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={secondaryColor ?? "#c9c9c9"}
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  );
};

const LoaderV2 = () => {
  return (
    <div className="loader-v2">
      <Oval
        color="#fff"
        wrapperStyle={{}}
        wrapperClass="loader__spinner"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#c9c9c9"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
    </div>
  );
};

const LoaderRotatingLines = () => (
  <RotatingLines
    visible={true}
    height="24"
    width="24"
    strokeColor="#c9c9c9"
    strokeWidth="3"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

export { Loader, LoaderV2, LoaderRotatingLines };
