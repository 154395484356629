import React, { useEffect } from "react";
import { format } from "date-fns";

import { useStore } from "../../../store";

import ReferralView from "./view";

const ReferralController = () => {
  const referralInfo = useStore((state) => state.referralInfo);
  const getLockedBalance = useStore((state) => state.getLockedBalance);
  const lockedBalance = useStore((state) => state.lockedBalance);

  const formattedDate = (date) => format(new Date(date), "dd MMM yyyy, HH:mm");

  const daysUntil = (targetDate) => {
    const now = new Date();
    const target = new Date(targetDate);
    const diff = target - now;

    if (diff <= 0) {
      return 0;
    }
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

    return days;
  };

  const referralLink = referralInfo?.link
    ? referralInfo.link.split("?")[0] +
      `?referral-code=${encodeURIComponent(referralInfo.code)}`
    : "";

  useEffect(() => {
    getLockedBalance();

    // eslint-disable-next-line
  }, []);

  return (
    <ReferralView
      referralInfo={referralInfo}
      referralLink={referralLink}
      lockedBalance={lockedBalance}
      formattedDate={formattedDate}
      daysUntil={daysUntil}
    />
  );
};

export default ReferralController;
