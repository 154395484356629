import { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";

import { LoaderRotatingLines } from "../components/ui/Loader";

import { checkTransactionStatusAPI } from "../api";

const usePaymentProgress = ({ notification, setNotification }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const notificationRef = createRef(null);
  const [paymentTm, setPaymentTm] = useState(null);

  const txHash = notification.value;

  const checkPayment = async () => {
    checkTransactionStatusAPI({
      transaction_hash: txHash,
    })
      .then((resp) => {
        const status = resp.data.status;

        switch (status) {
          case "confirmed":
            enqueueSnackbar("Your payment has been processed", {
              variant: "success",
            });
            closeSnackbar(notificationRef.current);
            localStorage.removeItem("paymentTxHash");
            setNotification({ type: "", value: "" });
            navigate("/?show-successful-payment=true");
            break;
          case "pending":
            const newTm = setTimeout(() => {
              checkPayment();
            }, 5000);

            setPaymentTm(newTm);
            break;
          default:
            enqueueSnackbar("Payment failed", { variant: "error" });
            closeSnackbar(notificationRef.current);
            localStorage.removeItem("paymentTxHash");
            setNotification({ type: "", value: "" });
            break;
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data.message, { variant: "error" });

        if (e.response.status === 404) {
          closeSnackbar(notificationRef.current);
          localStorage.removeItem("paymentTxHash");
          setNotification({ type: "", value: "" });
        } else {
          const newTm = setTimeout(() => {
            checkPayment();
          }, 5000);

          setPaymentTm(newTm);
        }
      });
  };

  useEffect(() => {
    if (txHash) {
      const notificationId = enqueueSnackbar("Your payment is processing...", {
        variant: "info",
        persist: true,
        action: () => (
          <div style={{ position: "relative" }}>
            <LoaderRotatingLines />
          </div>
        ),
      });
      notificationRef.current = notificationId;

      checkPayment();
    }

    return () => {
      if (notificationRef.current) {
        closeSnackbar(notificationRef.current);
      }
      if (paymentTm) {
        clearTimeout(paymentTm);
      }
    };
    // eslint-disable-next-line
  }, [notification]);
};

export { usePaymentProgress };
