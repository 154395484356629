import React, { createContext, useContext, useState } from "react";

import { usePaymentProgress } from "../../hooks/usePaymentProgress";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const initialNotification = localStorage.getItem("paymentTxHash")
    ? {
        type: "paymentTxHash",
        value: localStorage.getItem("paymentTxHash"),
      }
    : { type: "", value: "" };
  const [notification, setNotification] = useState(initialNotification);

  usePaymentProgress({ notification, setNotification });

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
