import "./index.scss";

import React, { useEffect, useRef } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { useStore } from "../../../../store";

import Button from "../../../ui/Button";

import Days30Icon from "../../../../assets/plan/30-days.svg";

const PlanTable = ({ isHomePage, handleSelectPlan }) => {
  const freePlanRef = useRef(null);
  const plansContainerRef = useRef(null);
  const firstPlanRef = useRef(null);
  const lastPlanRef = useRef(null);

  const { ONE_WEEK_PLAN, ONE_MONTH_PLAN, ONE_YEAR_PLAN, ONE_YEAR_TRIAL_PLAN } =
    useStore();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: freePlanRef.current,
        start: "top 40%",
      },
    });

    if (isHomePage) {
      tl.fromTo(
        freePlanRef.current,
        { opacity: 0 },
        { opacity: 1, delay: 0.25, duration: 0.7, ease: "power2.inOut" }
      )
        .fromTo(
          plansContainerRef.current,
          { opacity: 0 },
          { opacity: 1, duration: 1, ease: "power2.inOut" }
        )
        .fromTo(
          firstPlanRef.current,
          {
            transform: "translate(-50%, 0)",
          },
          {
            opacity: 1,
            duration: 1,
            transform: "translate(0, 0)",
            ease: "power2.inOut",
          },
          "<"
        )
        .fromTo(
          lastPlanRef.current,
          {
            transform: "translate(50%, 0)",
          },
          {
            duration: 1,
            transform: "translate(0, 0)",
            ease: "power2.inOut",
          },
          "<"
        );
    }

    return () => {
      tl.kill();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={isHomePage ? "plans-home" : ""} id="prices">
      <h2 className="plans-free__title">Get VPN4U subscription</h2>
      <div className="plans-free" ref={freePlanRef}>
        <div className="plans-free__card">
          <h3>
            Try VPN4U for <span>FREE</span>
          </h3>
          <Button
            mode="secondary"
            fullWidth
            onClick={() => handleSelectPlan(ONE_YEAR_TRIAL_PLAN, "freeTrial")}
          >
            START FOR FREE
          </Button>
        </div>
      </div>
      <h2 className="plans__title">
        Or select your
        <br /> subscription plan:
      </h2>
      <div className="plans" ref={plansContainerRef}>
        <div className="plans__item" ref={firstPlanRef}>
          <div>
            <p className="plans__period">1 Week</p>
          </div>
          <div>
            <h2 className="plans__curr-price">
              ${ONE_WEEK_PLAN.stripe.unit_amount}
            </h2>
            <p className="plans__lbl">per week</p>
          </div>
          <div className="plans__item-btm">
            <Button
              mode="primary"
              className="t_get-1-month-btn"
              onClick={() => handleSelectPlan(ONE_WEEK_PLAN)}
              fullWidth
            >
              Get 1 week for ${ONE_WEEK_PLAN.stripe.unit_amount}
            </Button>
            <p className="plan-table__head-money-back">
              <img src={Days30Icon} alt="30 days" />
              <span>30-day money-back guarantee</span>
            </p>
          </div>
        </div>

        <div className="plans__ben">
          <div className="plans__ben-top">
            <p>Best Deal</p>
          </div>
          <div className="plans__item">
            <div>
              <p className="plans__period">12 Months</p>
              <p className="plans__save">
                SAVE {ONE_YEAR_PLAN.stripe.discount}%
              </p>
            </div>
            <div>
              <p className="plans__old-price">
                ${ONE_MONTH_PLAN.stripe.unit_amount}
              </p>
              <h2 className="plans__curr-price">
                ${ONE_YEAR_PLAN.stripe.unit_amount_monthly}
              </h2>
              <p className="plans__lbl">per month</p>
            </div>
            <div className="plans__item-btm">
              <Button
                mode="primary"
                className="t_get-24-months-btn"
                onClick={() => handleSelectPlan(ONE_YEAR_PLAN)}
                fullWidth
              >
                Get 12 months for ${ONE_YEAR_PLAN.stripe.unit_amount}
              </Button>
              <Button
                mode="secondary"
                className="t_get-24-months-btn"
                onClick={() =>
                  handleSelectPlan(ONE_YEAR_PLAN, false, "onlyCrypto")
                }
                fullWidth
              >
                Pay with Cardano Tokens
              </Button>
              <p className="plan-table__head-money-back">
                <img src={Days30Icon} alt="30 days" />
                <span>30-day money-back guarantee</span>
              </p>
            </div>
          </div>
        </div>

        <div className="plans__item" ref={lastPlanRef}>
          <div>
            <p className="plans__period">1 Month</p>
          </div>
          <div>
            <h2 className="plans__curr-price">
              ${ONE_MONTH_PLAN.stripe.unit_amount}
            </h2>
            <p className="plans__lbl">per month</p>
          </div>
          <div className="plans__item-btm">
            <Button
              mode="primary"
              className="t_get-1-month-btn"
              onClick={() => handleSelectPlan(ONE_MONTH_PLAN)}
              fullWidth
            >
              Get 1 month for ${ONE_MONTH_PLAN.stripe.unit_amount}
            </Button>
            <p className="plan-table__head-money-back">
              <img src={Days30Icon} alt="30 days" />
              <span>30-day money-back guarantee</span>
            </p>
          </div>
        </div>
      </div>
      {/*isHomePage && (
        <p className="plans-home__any-questions">
          Do you have any questions? Write <a href=" ">directly here</a> .
        </p>
      )*/}
    </div>
  );
};

export default PlanTable;
