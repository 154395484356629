import "./index.scss";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { Modal } from "@mui/material";

import { useStore } from "../../store";

import Footer from "../HomePage/Footer";
import DownloadTab from "./DownloadTab";
import PlansTab from "./PlansTab";
import BillingTab from "./BillingTab";
import SubscriptionsTab from "./SubscriptionsTab";
import ReportsTab from "./ReportsTab";
import ReferralTab from "./ReferralTab";
import SettingsTab from "./SettingsTab";

import LogoutPopup from "../ui/LogoutPopup";
import PaymentMethodPopup from "../ui/PaymentMethodPopup";
import WithdrawPopup from "../ui/WithdrawPopup";

import { isAuthorizedUser } from "../../utils";

import { PROFILE_TABS } from "../../const/general";

import logoutIcon from "../../assets/logout-icon.svg";
import popupBtnIcon from "../../assets/popup-btn.svg";
import closeIcon from "../../assets/close_icon.svg";

import { useSelectPlan } from "../../hooks/useSelectPlan";

import ConnectWalletModal from "../ui/ConnectWalletModal";

const ProfilePage = () => {
  const isAuthorized = isAuthorizedUser();

  const navigate = useNavigate();

  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);
  const [activeTab, setActiveTab] = useState(PROFILE_TABS[0].name);
  const [connectWalletModal, setConnectWalletModal] = useState({
    show: false,
    onSuccess: () => null,
    onFail: () => null,
  });

  const popupBtnRef = useRef(null);

  const { handleSelectPlan, paymentMethodPopup, setPaymentMethodPopup } =
    useSelectPlan();

  const user = useStore((state) => state.user);
  const getMe = useStore((state) => state.getMe);
  const getProfileData = useStore((state) => state.getProfileData);

  useEffect(() => {
    if (!isAuthorized) {
      navigate("/");
    } else {
      getMe();
      getProfileData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="profile-wrapper">
        <div className="profile">
          <header className="profile__header">
            <div
              className="header__logo"
              onClick={() => navigate("/")}
              style={{ margin: "0 auto" }}
            >
              <img src="/images/logo-vpn4u.svg" alt="VPN logo" />
            </div>
          </header>
          <div className="profile__box">
            <div className="profile__top">
              <h2>YOUR PROFILE</h2>
              <p className="profile__top-item">
                Account: <span>{user.email}</span>
              </p>
              <div className="profile__top-withdraw">
                <p className="profile__top-item">
                  Balance:{" "}
                  <span>{Number(user.balance).toFixed(2)}&nbsp;USDT</span>
                  <span>
                    ,&nbsp;{Number(user.c_four_balance).toFixed(2)}&nbsp;$C4
                  </span>
                </p>
                {Number(user.balance) > 0 || Number(user.c_four_balance) ? (
                  <button
                    className="secondary-button get-started"
                    onClick={() => setShowWithdrawPopup(true)}
                  >
                    Withdraw
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <p
                className="profile__logout"
                onClick={() => setShowLogoutPopup(true)}
              >
                <span>Log out</span>
                <img src={logoutIcon} alt="logout" />
              </p>
              <img
                src={closeIcon}
                className="profile__close-icon"
                onClick={() => navigate("/")}
                alt="close"
              />
            </div>
            <div className="profile__tabs">
              {PROFILE_TABS.map((tab, i) => (
                <div
                  className={`profile__tab ${
                    tab.name === activeTab ? "active" : ""
                  }`}
                  key={i}
                  onClick={() => setActiveTab(tab.name)}
                >
                  {tab.title}
                </div>
              ))}
            </div>
            <div className="profile__tabs-m">
              <Popup
                trigger={
                  <button className="profile__tabs-m-btn">
                    <span>
                      {PROFILE_TABS?.length &&
                        PROFILE_TABS.find((x) => x.name === activeTab)?.title}
                    </span>
                    <img src={popupBtnIcon} alt="btn" ref={popupBtnRef} />
                  </button>
                }
                onOpen={() =>
                  !popupBtnRef.current.classList.contains("active") &&
                  popupBtnRef.current.classList.toggle("active")
                }
                onClose={() =>
                  popupBtnRef.current.classList.contains("active") &&
                  popupBtnRef.current.classList.toggle("active")
                }
              >
                {(close) => (
                  <div className="profile__tabs-m-popup">
                    <div className="profile__tabs-m-content">
                      {PROFILE_TABS.map((tab, i) => (
                        <div
                          className={`profile__tabs-m-item ${
                            tab.name === activeTab ? "active" : ""
                          }`}
                          key={i}
                          onClick={() => {
                            setActiveTab(tab.name);
                            close();
                          }}
                        >
                          {tab.title}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Popup>
            </div>

            <div className="profile__content">
              {activeTab === "download" ? (
                <DownloadTab />
              ) : activeTab === "plans" ? (
                <PlansTab handleSelectPlan={handleSelectPlan} />
              ) : activeTab === "billing" ? (
                <BillingTab />
              ) : activeTab === "subscriptions" ? (
                <SubscriptionsTab />
              ) : activeTab === "reports" ? (
                <ReportsTab />
              ) : activeTab === "referral" ? (
                <ReferralTab />
              ) : activeTab === "settings" ? (
                <SettingsTab />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>

      <Modal open={showLogoutPopup} onClose={() => setShowLogoutPopup(false)}>
        <LogoutPopup close={() => setShowLogoutPopup(false)} />
      </Modal>

      <Modal
        open={showWithdrawPopup}
        onClose={() => setShowWithdrawPopup(false)}
      >
        <WithdrawPopup close={() => setShowWithdrawPopup(false)} />
      </Modal>

      <Modal
        open={paymentMethodPopup.show}
        onClose={() => setPaymentMethodPopup({ show: false })}
      >
        <PaymentMethodPopup
          close={() => setPaymentMethodPopup({ show: false })}
          payViaStripe={paymentMethodPopup.payViaStripe}
          payViaPaypal={paymentMethodPopup.payViaPaypal}
          payViaCrypto={paymentMethodPopup.payViaCrypto}
          freeTrial={paymentMethodPopup.freeTrial}
          selectedPlan={paymentMethodPopup.selectedPlan}
          setConnectWalletModal={setConnectWalletModal}
        />
      </Modal>
      <Modal
        open={connectWalletModal.show}
        onClose={() => {
          setConnectWalletModal({ show: false });
          connectWalletModal.onFail();
        }}
      >
        <ConnectWalletModal
          close={() => {
            setConnectWalletModal({ show: false });
            connectWalletModal.onFail();
          }}
          onSuccess={connectWalletModal.onSuccess}
        />
      </Modal>
    </>
  );
};

export default ProfilePage;
