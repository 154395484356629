import "./index.scss";

import React from "react";

import { TextField } from "@mui/material";

import { LoaderV2 } from "../Loader";
import BaseModal from "../BaseModal";
import Button from "../Button";
import RadioButton from "../RadioButton";

import NumberField from "../NumberField";

import { getRem } from "../../../utils";

import { CURRENCIES } from "./const";

const WithdrawPopupView = ({
  close,
  isLoading,
  mode,
  withdrawCurrency,
  setWithdrawCurrency,
  setMode,
  handleSaveRequestData,
  handleSubmit,
  requestData,
  maxAmount,
}) => {
  return (
    <BaseModal close={close}>
      <div>
        {isLoading && <LoaderV2 />}
        {mode === "initial" ? (
          <form
            onSubmit={handleSaveRequestData}
            className="withdraw-popup__form"
            id="withdraw-form"
          >
            <h2 className="base-modal__title">Withdraw</h2>

            <p className="withdraw-popup__lbl">
              Select the currency for withdrawal
            </p>

            <div className="withdraw-popup__currency">
              <label className="withdraw-popup__currency-item">
                <RadioButton
                  name="withdraw-currency"
                  checked={withdrawCurrency === CURRENCIES.usdt}
                  onChange={() => setWithdrawCurrency(CURRENCIES.usdt)}
                />
                <span>{CURRENCIES.usdt}</span>
              </label>
              <label className="withdraw-popup__currency-item">
                <RadioButton
                  name="withdraw-currency"
                  checked={withdrawCurrency === CURRENCIES.C4}
                  onChange={() => setWithdrawCurrency(CURRENCIES.C4)}
                />
                <span>{CURRENCIES.C4}</span>
              </label>
            </div>

            <p className="withdraw-popup__lbl">
              Enter the amount of USDT you to withdraw
            </p>
            <NumberField
              required
              name="amount"
              id="outlined-basic4"
              label="Enter amount"
              maxValue={maxAmount}
            />
            <p className="withdraw-popup__lbl">
              Enter your address in the TRC20 network
            </p>
            <TextField
              required
              name="walletAddress"
              type="text"
              id="outlined-basic5"
              label="Enter your address"
              variant="outlined"
              className="text-field"
              sx={{
                "& input:-webkit-autofill": {
                  transitionDelay: "9999s",
                  transitionProperty: "background-color, color",
                },
              }}
            />

            <div className="withdraw-popup__btns">
              <Button
                mode="primary"
                type="button"
                fullWidth
                className="mt-20"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                mode="secondary"
                type="submit"
                fullWidth
                className="mt-20"
              >
                Next Step
              </Button>
            </div>
          </form>
        ) : (
          <div>
            <h2 className="base-modal__title">Confirm withdraw</h2>

            <div className="withdraw-popup__confirm">
              <div>
                <p>You withdraw:</p>
                <h3 className="withdraw-popup__confirm-amount">
                  {requestData.amount.toFixed(2)} {withdrawCurrency}
                </h3>
              </div>
              <div>
                <p>to an address (TRC20 network):</p>
                <h3 className="withdraw-popup__confirm-address">
                  {requestData.wallet_address}
                </h3>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: getRem(20),
              }}
            >
              <Button
                mode="primary"
                fullWidth
                className="mt-20"
                onClick={() => setMode("initial")}
              >
                Back
              </Button>
              <Button
                mode="secondary"
                fullWidth
                className="mt-20"
                onClick={handleSubmit}
              >
                Confirm withdraw
              </Button>
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default WithdrawPopupView;
