import { useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { applyCoupon, applyCouponNA } from "../../../api";

import { requiredAuth } from "../../../utils";

import { PAYMENT_METHODS } from "./const";

import { TOKENS } from "./const";

const useService = ({
  selectedPlan,
  payViaPaypal,
  payViaStripe,
  payViaCrypto,
  freeTrial,
  email,
  authorizeRequest,
  setConnectWalletModal,
  close,
}) => {
  const withAuth = !email;

  const initialPaymentMethod = payViaStripe
    ? PAYMENT_METHODS.stripe 
      : payViaPaypal
        ? PAYMENT_METHODS.paypal
          : PAYMENT_METHODS.crypto;
  const initialCurrencyToken = payViaCrypto ? {
    ...selectedPlan.crypto[TOKENS.c4.id].token_payment_plan,
    price: Number(selectedPlan.crypto[TOKENS.c4.id].price),
    planId: selectedPlan.crypto[TOKENS.c4.id].id,
  } : null

  const [selectedPM, setSelectedPM] = useState(initialPaymentMethod);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountDaysAmount, setDiscountDaysAmount] = useState(0);
  const [promocode, setPromocode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currencyToken, setCurrencyToken] = useState(initialCurrencyToken);

  const { enqueueSnackbar } = useSnackbar();

  const applyAuthLayer = (func, props) => {
    if (withAuth) {
      requiredAuth(() => func(props), authorizeRequest)();
    } else {
      func(props);
    }
  };

  const handlePay = (e) => {
    e.preventDefault();

    if (selectedPM === PAYMENT_METHODS.crypto && !currencyToken) {
      return;
    }

    const promocode =
      !freeTrial &&
      selectedPM !== PAYMENT_METHODS.paypal &&
      selectedPM !== PAYMENT_METHODS.crypto
        ? e.target.promocode.value
        : undefined;

    const payStripe = () => {
      payViaStripe({ promocode });
      close();
    };

    const payPaypal = () => {
      payViaPaypal({ promocode });
      close();
    };

    const payCrypto = () => {
      setIsLoading(true);

      setConnectWalletModal({
        show: true,
        onSuccess: () => {
          setIsLoading(true);
          payViaCrypto({
            currencyToken,
            onSuccess: () => {
              close();
              setIsLoading(false);
            },
            onFail: () => {
              setIsLoading(false);
            },
          });
        },
        onFail: () => {
          setIsLoading(false);
        },
      });
    };

    switch (selectedPM) {
      case PAYMENT_METHODS.stripe:
        applyAuthLayer(payStripe);
        break;
      case PAYMENT_METHODS.paypal:
        applyAuthLayer(payPaypal);

        break;
      case PAYMENT_METHODS.crypto:
        applyAuthLayer(payCrypto);
        break;
      default:
        break;
    }
  };

  const verifyPromocode = ({ promocode }) => {
    const applyCouponAPI = withAuth ? applyCoupon : applyCouponNA;
    const data = {
      coupon: promocode,
      plan: selectedPlan.stripe.id,
      ...(withAuth ? {} : { email }),
    };

    applyCouponAPI(data)
      .then((resp) => {
        if (resp.data.type === "days") {
          setDiscountDaysAmount(resp.data.days);
        }
        setDiscountAmount(resp.data.percent);
        enqueueSnackbar("Applied", { variant: "success" });
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      });
  };

  const handleVerifyPromocode = () => {
    applyAuthLayer(verifyPromocode, { promocode });
  };

  useEffect(() => {
    setDiscountAmount(0);
    setDiscountDaysAmount(0);
  }, [promocode]);

  return {
    selectedPM,
    setSelectedPM: (newPm, token) => {
      setSelectedPM(newPm);
      setCurrencyToken(token ? token : null);
    },
    currencyToken,
    discountAmount,
    discountDaysAmount,
    isLoading,
    setPromocode,
    handlePay,
    handleVerifyPromocode,
  };
};

export { useService };
