import "./index.scss";

import React, { useState } from "react";
import { Modal } from "@mui/material";

import { useStore } from "../../../store";

import PaymentMethodPopup from "../../ui/PaymentMethodPopup";
import Button from "../../ui/Button";

import Days30Icon from "../../../assets/plan/30-days.svg";

import ConnectWalletModal from "../../ui/ConnectWalletModal";

import { useSelectPlan } from "../../../hooks/useSelectPlan";

const Plans = ({ email }) => {
  const [connectWalletModal, setConnectWalletModal] = useState({
    show: false,
    onSuccess: () => null,
    onFail: () => null,
  });

  const { ONE_WEEK_PLAN, ONE_MONTH_PLAN, ONE_YEAR_PLAN, ONE_YEAR_TRIAL_PLAN } =
    useStore();

  const { handleSelectPlan, paymentMethodPopup, setPaymentMethodPopup } =
    useSelectPlan({ email });

  return (
    <div>
      <h2 className="paywall-plans-free__title">Get VPN4U subscription</h2>
      <div className="paywall-plans-free">
        <div className="paywall-plans-free__card">
          <h3>
            Try VPN4U for <span>FREE</span>
          </h3>
          <Button
            mode="secondary"
            fullWidth
            onClick={() => handleSelectPlan(ONE_YEAR_TRIAL_PLAN, "freeTrial")}
          >
            START FOR FREE
          </Button>
        </div>
      </div>
      <div className="paywall-plans">
        <h2 className="paywall-plans__title">
          Or select your subscription plan:
        </h2>
        <div className="paywall-plan">
          <p className="paywall-plan__period">1 Week</p>
          <p className="paywall-plan__save" />

          <div className="paywall-plan__price-box">
            <h2 className="paywall-plan__curr-price">
              ${ONE_WEEK_PLAN.stripe.unit_amount}
            </h2>
            <p className="paywall-plan__lbl">per week</p>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              mode="primary"
              className="t_get-1-month-btn"
              onClick={() => handleSelectPlan(ONE_WEEK_PLAN)}
            >
              Get 1 week for ${ONE_WEEK_PLAN.stripe.unit_amount}
            </Button>
          </div>
          <p className="plan-table__head-money-back">
            <img src={Days30Icon} alt="30 days" />
            <span>30-day money-back guarantee</span>
          </p>
        </div>

        <div className="paywall__ben">
          <div className="paywall__ben-top">
            <p>Best Deal</p>
          </div>
          <div className="paywall-plan">
            <p className="paywall-plan__period">12 Months</p>
            <p className="paywall-plan__save">
              SAVE {ONE_YEAR_PLAN.stripe.discount}%
            </p>

            <div className="paywall-plan__price-box">
              <p className="paywall-plan__old-price">
                ${ONE_MONTH_PLAN.stripe.unit_amount}
              </p>
              <p className="paywall-plan__curr-price">
                ${ONE_YEAR_PLAN.stripe.unit_amount_monthly}
              </p>
              <p className="paywall-plan__lbl">per month</p>
            </div>
            <div className="paywall-plan__btns">
              <Button
                mode="primary"
                className="t_get-24-months-btn"
                onClick={() => handleSelectPlan(ONE_YEAR_PLAN)}
                fullWidth
              >
                Get 12 months for ${ONE_YEAR_PLAN.stripe.unit_amount}
              </Button>
              <Button
                mode="secondary"
                className="t_get-24-months-btn"
                onClick={() =>
                  handleSelectPlan(ONE_YEAR_PLAN, "", "onlyCrypto")
                }
                fullWidth
              >
                Pay with Cardano Tokens
              </Button>
            </div>
            <p className="plan-table__head-money-back">
              <img src={Days30Icon} alt="30 days" />
              <span>30-day money-back guarantee</span>
            </p>
          </div>
        </div>

        <div className="paywall-plan">
          <p className="paywall-plan__period">1 Month</p>
          <p className="paywall-plan__save" />

          <div className="paywall-plan__price-box">
            <h2 className="paywall-plan__curr-price">
              ${ONE_MONTH_PLAN.stripe.unit_amount}
            </h2>
            <p className="paywall-plan__lbl">per month</p>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              mode="primary"
              className="t_get-1-month-btn"
              onClick={() => handleSelectPlan(ONE_MONTH_PLAN)}
            >
              Get 1 month for ${ONE_MONTH_PLAN.stripe.unit_amount}
            </Button>
          </div>
          <p className="plan-table__head-money-back">
            <img src={Days30Icon} alt="30 days" />
            <span>30-day money-back guarantee</span>
          </p>
        </div>
      </div>

      <Modal
        open={paymentMethodPopup.show}
        onClose={() => setPaymentMethodPopup({ show: false })}
      >
        <PaymentMethodPopup
          close={() => setPaymentMethodPopup({ show: false })}
          payViaStripe={paymentMethodPopup.payViaStripe}
          payViaCrypto={paymentMethodPopup.payViaCrypto}
          freeTrial={paymentMethodPopup.freeTrial}
          selectedPlan={paymentMethodPopup.selectedPlan}
          email={email}
          setConnectWalletModal={setConnectWalletModal}
        />
      </Modal>
      <Modal
        open={connectWalletModal.show}
        onClose={() => {
          setConnectWalletModal({ show: false });
          connectWalletModal.onFail();
        }}
      >
        <ConnectWalletModal
          close={() => {
            setConnectWalletModal({ show: false });
            connectWalletModal.onFail();
          }}
          onSuccess={connectWalletModal.onSuccess}
        />
      </Modal>
    </div>
  );
};

export default Plans;
