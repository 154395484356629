
const PAYMENT_METHODS = {
  stripe: "stripe",
  paypal: "paypal",
  crypto: "crypto",
};
const TOKENS = {
  c4: {
    id: "$C4",
  },
};

export { PAYMENT_METHODS, TOKENS };
