import "./index.scss";

import React from "react";

import { useStore } from "../../../store";

import BaseModal from "../BaseModal";
import { LoaderV2 } from "../Loader";

import { useConnectWallet } from "./useConnectWallet";

const ConnectWalletModal = ({ close, onSuccess }) => {
  const { availableWallets } = useStore();

  const { handleConnectWallet } = useConnectWallet({
    onSuccess,
    close,
  });

  const isLoading = false;

  const simplifyWalletName = (walletName) =>
    walletName.replace(/wallet/gi, "");

  return (
    <BaseModal close={close}>
      {isLoading && <LoaderV2 />}

      <h2 className="base-modal__title change-pass__title">
        Connect your wallet
      </h2>

      <div className="connect-wallet__container">
        <ul className="connect-wallet__wallets">
          {availableWallets.length > 0 ? (
            availableWallets.map((wallet, i) => (
              <li key={i} className="connect-wallet__wallets-item">
                <button onClick={() => handleConnectWallet(wallet)}>
                  <div className="connect-wallet__wallet-img">
                    <img src={wallet.icon} alt="wallet" />
                  </div>
                  <span>{simplifyWalletName(wallet.name)}</span>
                </button>
              </li>
            ))
          ) : (
            <p className="connect-wallet__no-wallets">No available wallets</p>
          )}
        </ul>
      </div>
    </BaseModal>
  );
};

export default ConnectWalletModal;
