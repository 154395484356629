import "./index.scss";

import React from "react";
import { TextField } from "@mui/material";

import BaseModal from "../BaseModal";
import Button from "../Button";
import RadioButton from "../RadioButton";
import { LoaderV2 } from "../Loader";
import Dropdown from "../Dropdown";

import VisaImg from "../../../assets/payment-systems/visa.svg";
import PaypalImg from "../../../assets/payment-systems/paypal.svg";

import { getDateIn3DaysFormatted } from "../../../utils";

import { useService } from "./useService";

import { PAYMENT_METHODS } from "./const";

const PaymentMethodPopup = ({
  selectedPlan,
  freeTrial,
  payViaStripe,
  payViaPaypal,
  payViaCrypto,
  email,
  authorizeRequest,
  setConnectWalletModal,
  close,
}) => {
  const currPrice = selectedPlan.stripe.unit_amount;
  const isShowStripe = !!payViaStripe;
  const isShowPaypal = !!payViaPaypal && selectedPlan.paypal?.id;
  const isShowCrypto = !!payViaCrypto;

  const {
    selectedPM,
    setSelectedPM,
    currencyToken,
    discountAmount,
    discountDaysAmount,
    isLoading,
    setPromocode,
    handlePay,
    handleVerifyPromocode,
  } = useService({
    selectedPlan,
    payViaPaypal,
    payViaStripe,
    payViaCrypto,
    freeTrial,
    email,
    authorizeRequest,
    setConnectWalletModal,
    close,
  });

  return (
    <BaseModal close={close} withoutOverflow={isShowCrypto && selectedPlan.crypto}>
      {isLoading && <LoaderV2 />}

      <form
        className={`select-pay-popup ${
          freeTrial ? "select-pay-popup_free-trial" : ""
        }`}
        onSubmit={handlePay}
      >
        <h2>{isShowCrypto && selectedPlan.crypto? "Select a token for payment" : "Select a payment method"}</h2>
        {freeTrial && (
          <p className="select-pay-popup__subtitle">
            WE WILL NOT CHARGE
            <br /> YOU NOW
          </p>
        )}
        <div className="select-pay-popup__box">
          {isShowStripe && (
            <label className="select-pay-popup__item">
              <RadioButton
                name="select-payment"
                checked={selectedPM === PAYMENT_METHODS.stripe}
                onChange={() => setSelectedPM(PAYMENT_METHODS.stripe)}
              />
              <p className="select-pay-popup__item-label">
                Pay with credit card
              </p>
              <img
                src={VisaImg}
                className="select-pay-popup__item-img"
                alt="stripe"
              />
            </label>
          )}
          {isShowPaypal && (
            <label className="select-pay-popup__item">
              <RadioButton
                name="select-payment"
                checked={selectedPM === PAYMENT_METHODS.paypal}
                onChange={() => setSelectedPM(PAYMENT_METHODS.paypal)}
              />
              <p className="select-pay-popup__item-label">Pay with PayPal</p>
              <img
                src={PaypalImg}
                className="select-pay-popup__item-img"
                alt="stripe"
              />
            </label>
          )}
          {isShowCrypto && selectedPlan.crypto ? (
            <>
              <Dropdown
                options={Object.keys(selectedPlan.crypto).map((token) => (
                  {
                    image: selectedPlan.crypto[token].token_payment_plan.icon,
                    label: selectedPlan.crypto[token].token_payment_plan.token_title,
                    value: selectedPlan.crypto[token].id,
                    tokenData: {
                      ...selectedPlan.crypto[token].token_payment_plan,
                      price: Number(selectedPlan.crypto[token].price),
                      planId: selectedPlan.crypto[token].id,
                    }
                  }))
                }
                onChange={(selectedOption) => 
                  setSelectedPM(PAYMENT_METHODS.crypto, selectedOption.tokenData)
                }
                defaultOption={{
                  image: currencyToken.icon,
                  label: currencyToken.token_title,
                  value: currencyToken.planId,
                  tokenData: currencyToken
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        {!freeTrial ? (
          <>
            {selectedPM === PAYMENT_METHODS.stripe && (
              <div className="select-pay-popup__promo">
                <p>Enter the promo code if you have one</p>
                <div className="select-pay-popup__promo-apply">
                  <TextField
                    name="promocode"
                    type="text"
                    id="outlined-basic4"
                    label="Your promo code"
                    variant="outlined"
                    className="text-field"
                    onChange={(e) => setPromocode(e.target.value)}
                  />
                  <Button
                    mode="secondary"
                    type="button"
                    onClick={handleVerifyPromocode}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )}
            <div className="select-pay-popup__btm discount">
              {selectedPM === PAYMENT_METHODS.stripe && (
                <div className="select-pay-popup__btm-lbl">
                  <p>Plan cost</p>
                  <p>${currPrice}</p>
                </div>
              )}
              {selectedPM === PAYMENT_METHODS.stripe && (
                <div className="select-pay-popup__btm-lbl">
                  <p>Code Discount</p>
                  {discountDaysAmount ? (
                    <p>{discountDaysAmount} free days</p>
                  ) : (
                    <p>
                      -&nbsp;$
                      {((Number(currPrice) * discountAmount) / 100).toFixed(2)}
                    </p>
                  )}
                </div>
              )}
              <div className="select-pay-popup__now">
                <p>Total</p>
                <p className="select-pay-popup__total-amount">
                  {selectedPM === PAYMENT_METHODS.crypto ? (
                    <span>
                      {Number(Number(currencyToken.price).toFixed(currencyToken.decimals))} {currencyToken.token_title}
                    </span>
                  ) : (
                    <span>
                      $
                      {(
                        Number(currPrice) -
                        (Number(currPrice) * discountAmount) / 100
                      ).toFixed(2)}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <Button mode="secondary" type="submit" fullWidth disabled={!selectedPM}>
              Go to payment
            </Button>
            {!isShowCrypto && (
              <p className="select-pay-popup__btm-lbl2">
                You can always cancel your subscription and get a refund within
                30 days after payment.
              </p>
            )}
          </>
        ) : (
          <div className="select-pay-popup__btm">
            <div className="select-pay-popup__now">
              <p>Total now</p>
              <p>
                <span>$0.00</span>
              </p>
            </div>
            <div className="select-pay-popup__future">
              <p>Total on {getDateIn3DaysFormatted()}</p>
              <p>
                <span>${currPrice}</span>
              </p>
            </div>
            <Button mode="secondary" type="submit" fullWidth>
              Go to payment
            </Button>
            <p className="select-pay-popup__btm-lbl">
              Start your 72-hour free trial with no charge. A yearly
              subscription begins automatically after the trial ends, unless
              cancelled
            </p>
          </div>
        )}
      </form>
    </BaseModal>
  );
};

export default PaymentMethodPopup;
