import { useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import { Lucid, Blockfrost } from "lucid-cardano";

import { useStore } from "../store";

import { openInNewTab } from "../utils";

import { useNotification } from "../providers/NotificationProvider";

import {
  subscribePaypal,
  subscribeStripe,
  subscribeStripeNA,
  subscribeCryptoAPI,
  subscribeCryptoNaAPI,
} from "../api";

import {
  BLOCKFROST_API,
  BLOCKFROST_PROJECT_ID,
  INNER_WALLET_CRYPTO_PAYMENTS,
} from "../const/config";

const useSelectPlan = ({ email } = {}) => {
  const [paymentMethodPopup, setPaymentMethodPopup] = useState({
    show: false,
    payViaStripe: () => null,
    payViaPaypal: () => null,
    payViaCrypto: () => null,
    selectedPlan: {},
    freeTrial: false,
  });

  const withAuth = !email;

  const { getProductsLoading, getAvailableWallets } = useStore();

  const { enqueueSnackbar } = useSnackbar();

  const { setNotification } = useNotification();

  const handleSelectPlan = (selectedPlan, isFreeTrial, onlyCrypto) => {
    if (getProductsLoading) {
      return;
    }

    const payViaStripe = ({ promocode } = {}) => {
      const subscribe = withAuth ? subscribeStripe : subscribeStripeNA;

      subscribe({
        plan: selectedPlan.stripe.id,
        isFreeTrial: !!isFreeTrial,
        ...(withAuth ? {} : { email }),
        ...(promocode ? { promocode } : {}),
      }).then((response) => {
        const checkoutUrl = response.data.checkout_url;
        openInNewTab(checkoutUrl);
      });
    };

    const payViaPaypal = ({ promocode } = {}) => {
      subscribePaypal({
        plan: selectedPlan.paypal.id,
        isFreeTrial: !!isFreeTrial,
        ...(promocode ? { promocode } : {}),
      }).then((response) => {
        const checkoutUrl = response.data.redirect_url;
        openInNewTab(checkoutUrl);
      });
    };

    const payViaCrypto = async ({ currencyToken, onSuccess, onFail }) => {
      const lucid = await Lucid.new(
          new Blockfrost(BLOCKFROST_API, BLOCKFROST_PROJECT_ID)
      );
      const connectedWallet = localStorage.getItem("connectedWallet");

      try {
        const api = await window.cardano[connectedWallet].enable();
        lucid.selectWallet(api);

        const tx = await lucid
            .newTx()
            .payToAddress(INNER_WALLET_CRYPTO_PAYMENTS, {
              //            lovelace: amount * 1000000,
              [currencyToken.token_asset]:
              Number(currencyToken.price.toFixed(currencyToken.decimals)) * Math.pow(10, currencyToken.decimals),
            })
            .complete();

        const signedTx = await tx.sign().complete();
        const txHash = await signedTx.submit();

        const subscribeCrypto = withAuth
            ? subscribeCryptoAPI
            : subscribeCryptoNaAPI;

        subscribeCrypto({
          plan: currencyToken.planId,
          amount: currencyToken.price,
          transaction_id: txHash,
          currency: currencyToken.token_name,
          ...(withAuth ? {} : { email }),
        })
            .then(() => {
              localStorage.setItem("paymentTxHash", txHash);
              setNotification({ type: "paymentTxHash", value: txHash });
              onSuccess();
            })
            .catch((e) => {
              console.log(e);
              onFail();
              enqueueSnackbar(e.response.data.message, { variant: "error" });
            });
      } catch (e) {
        console.log(e);
        onFail();
        if (e === "InputsExhaustedError") {
          enqueueSnackbar("Insufficient funds", {
            variant: "error",
          });
        }
        // e?.code === 2 means user declined transaction
        else if (e?.code !== 2 && e?.code !== -3) {
          // Unknown error
          enqueueSnackbar(e, { variant: "error" });
        }
      }
    };

    if (withAuth) {
      // FOR HOME & PROFILE PAGES
      setPaymentMethodPopup({
        show: true,
        selectedPlan: selectedPlan,
        freeTrial: !!isFreeTrial,
        ...(!onlyCrypto ? { payViaStripe } : {}),
        ...(!onlyCrypto ? { payViaPaypal } : {}),
        ...(onlyCrypto ? { payViaCrypto } : {}),
      });
    } else {
      // FOR PAYWALL PAGE
      setPaymentMethodPopup({
        show: true,
        selectedPlan: selectedPlan,
        freeTrial: !!isFreeTrial,
        ...(!onlyCrypto ? { payViaStripe } : {}),
        ...(onlyCrypto ? { payViaCrypto } : {}),
      });
    }
  };

  useEffect(() => {
    getAvailableWallets();
    // eslint-disable-next-line
  }, []);

  return { handleSelectPlan, paymentMethodPopup, setPaymentMethodPopup };
};

export { useSelectPlan };
