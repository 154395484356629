import { create } from "zustand";
import { enqueueSnackbar } from "notistack";

import {
  getMeAPI,
  getReferralInfoAPI,
  getLockedBalanceAPI,
  getPaymentHistoryAPI,
  getMySubscriptions,
  getIpInfoAPI,
  getProductsPaypalAPI,
  getProductsStripeAPI,
  getProductsCryptoAPI,
  getServersAPI,
} from "./api";

import { REFERRAL_TERMS } from "./const/general";

import { receivePaymentPlans } from "./services/payments";

const useStore = create((set) => ({
  user: {
    email: "",
    balance: "0.00",
    c_four_balance: "0.00",
    referralCode: "",
    isSubscribed: false,
  },
  getMe: () => {
    getMeAPI()
      .then((response) => {
        const userData = response.data;

        set(() => ({ user: userData }));
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      });
  },

  paymentHistory: [],
  getPaymentHistory: () => {
    set({ getPaymentHistoryLoading: true });
    getPaymentHistoryAPI()
      .then((r) => {
        const txs = r.data.transactions.slice().reverse();
        set({ paymentHistory: txs });
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e.response?.data.message ?? e, { variant: "error" });
      })
      .finally(() => {
        set({ getPaymentHistoryLoading: false });
      });
  },
  getPaymentHistoryLoading: false,

  mySubscription: null,
  getMySubscriptionLoading: false,
  getMySubscription: () => {
    set({ getMySubscriptionLoading: true });
    getMySubscriptions()
      .then((r) => {
        const subs = r.data.subscription?.length
          ? r.data.subscription.find((item) => item?.is_subscribed)
          : null;

        set({ mySubscription: subs });
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e.response?.data.message, { variant: "error" });
      })
      .finally(() => {
        set({ getMySubscriptionLoading: false });
      });
  },

  ipInfo: {
    ip: "",
    org: "",
  },
  getIpInfoLoading: false,
  getIpInfo: () => {
    set({ getIpInfoLoading: true });
    getIpInfoAPI()
      .then((resp) => {
        set({ ipInfo: resp.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ getIpInfoLoading: false });
      });
  },
  ONE_WEEK_PLAN: {
    stripe: {
      unit_amount: 1.99,
      unit_amount_monthly: 1.99,
      discount: 0,
    },
    paypal: {},
  },
  ONE_MONTH_PLAN: {
    stripe: {
      unit_amount: 6.49,
      unit_amount_monthly: 6.49,
      discount: 0,
    },
    paypal: {},
  },
  ONE_YEAR_PLAN: {
    stripe: {
      unit_amount: 29.88,
      unit_amount_monthly: 2.49,
      discount: 62,
    },
    paypal: {},
  },
  ONE_YEAR_TRIAL_PLAN: {
    stripe: {
      unit_amount: 29.88,
      unit_amount_monthly: 2.49,
      discount: 62,
    },
    paypal: {},
  },
  getProductsLoading: false,
  getProducts: async () => {
    set({ getProductsLoading: true });

    Promise.all([
      getProductsStripeAPI(),
      getProductsPaypalAPI(),
      getProductsCryptoAPI(),
    ])
      .then(([stripePlansResp, paypalPlansResp, cryptoPlansResp]) => {
        const paymentPlans = receivePaymentPlans(
          stripePlansResp.data,
          paypalPlansResp.data,
          cryptoPlansResp.data
        );

        set({ ...paymentPlans, getProductsLoading: false });
      })
      .catch(() => {
        set({ getProductsLoading: false });
      });
  },

  servers: [],
  getServersLoading: false,
  getServers: () => {
    set({ getServersLoading: true });
    getServersAPI()
      .then((resp) => {
        const result = resp.data.flatMap((item) => item.servers);
        set({ servers: result });
      })
      .finally(() => {
        set({ getServersLoading: false });
      });
  },
  getHomeData: () => {
    set(() => ({
      getServersLoading: true,
      getIpInfoLoading: true,
      getProductsLoading: true,
    }));

    Promise.all([
      getServersAPI(),
      getProductsStripeAPI(),
      getProductsPaypalAPI(),
      getProductsCryptoAPI(),
    ])
      .then(
        ([serversResp, stripePlansResp, paypalPlansResp, cryptoPlansResp]) => {
          const paymentPlans = receivePaymentPlans(
            stripePlansResp.data,
            paypalPlansResp.data,
            cryptoPlansResp.data
          );

          set({
            servers: serversResp.data.flatMap((item) => item.servers),
            getServersLoading: false,
            getProductsLoading: false,
            ...paymentPlans,
          });
        }
      )
      .catch(() => {
        set({
          getServersLoading: false,
          getProductsLoading: false,
        });
      });
  },
  referralInfo: {
    count_referrals: 0,
    total_income_from_referrals: 0,
    link: "",
    code: "",
    first_purchase_rate: REFERRAL_TERMS.first,
    second_purchase_rate: REFERRAL_TERMS.second,
    constant_purchase_rate: REFERRAL_TERMS.standard,
  },
  getReferralInfo: () => {
    getReferralInfoAPI().then((referralInfoResp) => {
      set({ referralInfo: referralInfoResp.data });
    });
  },
  getProfileData: () => {
    set({
      getPaymentHistoryLoading: true,
      getProductsLoading: true,
      getMySubscriptionLoading: true,
    });

    Promise.all([
      getReferralInfoAPI(),
      getPaymentHistoryAPI(),
      getProductsStripeAPI(),
      getProductsPaypalAPI(),
      getProductsCryptoAPI(),
      getMySubscriptions(),
    ])
      .then(
        ([
          referralInfoResp,
          txsResp,
          stripePlansResp,
          paypalPlansResp,
          cryptoPlansResp,
          subscriptionResp,
        ]) => {
          const txs = txsResp.data.transactions.slice().reverse();
          const paymentPlans = receivePaymentPlans(
            stripePlansResp.data,
            paypalPlansResp.data,
            cryptoPlansResp.data
          );
          const subs = subscriptionResp.data.subscription?.length
            ? subscriptionResp.data.subscription.find(
                (item) => item?.is_subscribed
              )
            : null;

          set({
            referralInfo: referralInfoResp.data,
            paymentHistory: txs,
            mySubscription: subs,
            ...paymentPlans,
            getPaymentHistoryLoading: false,
            getProductsLoading: false,
            getMySubscriptionLoading: false,
          });
        }
      )
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e.response?.data.message, { variant: "error" });
        set({
          getPaymentHistoryLoading: false,
          getProductsLoading: false,
          getMySubscriptionLoading: false,
        });
      });
  },
  availableWallets: [],
  getAvailableWallets: () => {
    if (window.cardano) {
      const wallets = Object.keys(window.cardano).reduce((acc, key) => {
        const wallet = window.cardano[key];
        if (wallet && typeof wallet.enable === "function") {
          if (key === "typhon" && window.cardano.typhoncip30) {
            return acc;
          } else {
            return [...acc, { id: key, icon: wallet.icon, name: wallet.name }];
          }
        } else {
          return acc;
        }
      }, []);

      set({ availableWallets: wallets });
    }
  },
  lockedBalance: [],
  getLockedBalance: () => {
    getLockedBalanceAPI().then((resp) => {
      set({ lockedBalance: resp.data?.data ? resp.data.data : [] });
    });
  },
}));

export { useStore };
