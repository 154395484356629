import API from "./api-base";

import axios from "axios";

/** AUTH */

const register = ({ email, password, passwordConfirm, referral_code }) =>
  API.post("/auth/register", {
    email,
    password,
    passwordConfirm,
    referral_code,
  });

const emailVerify = ({ code }) => API.post("/auth/verify", { code });

const changePassword = ({ password, newPassword, newPasswordConfirm }) =>
  API.post("/auth/change-password", {
    password,
    newPassword,
    newPasswordConfirm,
  });

const logIn = ({ email, password }) =>
  API.post("/auth/login", { email, password });

const logOut = () => API.post("/auth/session");

const resetPasswordInitiate = ({ email }) =>
  API.post("/auth/reset-password/initiate", { email });

const resetPasswordCheck = ({ code }) =>
  API.post("/auth/reset-password/check", { code });

const resetPasswordConfirm = ({ code, newPassword, passwordNewConfirm }) =>
  API.post("/auth/reset-password/confirm", {
    code,
    newPassword,
    passwordNewConfirm,
  });

/** USER */

const getMeAPI = () => API.get("/user/me");

const getReferralInfoAPI = () => API.get("/referral/information");

const getLockedBalanceAPI = () => API.get("/referral/locked-balance");

const withdrawFundsAPI = ({ wallet_address, amount, currency }) =>
  API.post("/withdrawals/create", { wallet_address, amount, currency });

/** SERVERS */

const getServersAPI = () => API.get("/servers/flags");

/** PAYMENT */

const getPaymentHistoryAPI = () =>
  API.get("/subscription/user/payment-history");

const getMySubscriptions = () => API.get("/subscription/my-subscription");

const unSubscribe = () => API.post("/subscription/unsubscribe");

const getProductsStripeAPI = () =>
  API.get("/subscription/stripe/product-pricing");

const getProductsPaypalAPI = () =>
  API.get("/subscription/paypal/product-pricing");

const getProductsCryptoAPI = () =>
  API.get("/subscription/cardano/product-pricing");

const subscribeStripe = ({ plan, isFreeTrial, promocode }) =>
  API.post("/subscription/stripe/subscribe", {
    plan,
    isFreeTrial,
    promocode,
  });

const subscribePaypal = ({ plan, isFreeTrial, promocode }) =>
  API.post("/subscription/paypal/subscribe", {
    plan,
    isFreeTrial,
    promocode,
  });

const subscribeStripeNA = ({ plan, email, isFreeTrial, promocode }) =>
  API.post("/subscription/non-auth/stripe/subscribe", {
    plan,
    email,
    isFreeTrial,
    promocode,
  });

const subscribeCryptoAPI = ({ plan, amount, currency, transaction_id }) =>
  API.post("/subscription/cardano/subscribe", {
    plan,
    amount,
    currency,
    transaction_id,
  });

const subscribeCryptoNaAPI = ({
  plan,
  email,
  amount,
  currency,
  transaction_id,
}) =>
  API.post("/subscription/non-auth/cardano/subscribe", {
    plan,
    email,
    amount,
    currency,
    transaction_id,
  });

const applyCoupon = ({ coupon, plan }) =>
  API.post("/apply-coupon", { coupon, plan });

const applyCouponNA = ({ coupon, plan, email }) =>
  API.post("/non-auth/apply-coupon", { coupon, plan, email });

const checkTransactionStatusAPI = ({ transaction_hash }) =>
  API.get(`/subscription/cardano/check-transaction-status/${transaction_hash}`);

/** PUBLIC */

const getIpInfoAPI = () => axios.get("https://ipinfo.io/json");

export {
  /** AUTH */
  register,
  emailVerify,
  changePassword,
  logIn,
  logOut,
  resetPasswordInitiate,
  resetPasswordCheck,
  resetPasswordConfirm,
  /** USER */
  getMeAPI,
  getReferralInfoAPI,
  getLockedBalanceAPI,
  withdrawFundsAPI,
  /** SERVERS */
  getServersAPI,
  /** PAYMENT */
  subscribeStripe,
  subscribePaypal,
  subscribeStripeNA,
  subscribeCryptoNaAPI,
  subscribeCryptoAPI,
  getProductsStripeAPI,
  getProductsPaypalAPI,
  getProductsCryptoAPI,
  getPaymentHistoryAPI,
  getMySubscriptions,
  unSubscribe,
  checkTransactionStatusAPI,
  /** PUBLIC */
  getIpInfoAPI,
  applyCoupon,
  applyCouponNA,
};
