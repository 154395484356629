const API_URL = process.env.REACT_APP_API_URL;
const BLOCKFROST_API = process.env.REACT_APP_BLOCKFROST_API;
const BLOCKFROST_PROJECT_ID = process.env.REACT_APP_BLOCKFROST_PROJECT_ID;
const INNER_WALLET_CRYPTO_PAYMENTS = process.env.REACT_APP_INNER_WALLET;

const DOWNLOAD_LINKS = {
  ANDROID: "https://play.google.com/store/apps/details?id=com.vpn4",
  IOS: "https://apps.apple.com/ua/app/vpn4u-fast-and-secure-vpn/id6503442393",
  CHROME_EXT:
    "https://chromewebstore.google.com/detail/vpn4uio/ikfbplbmhdhmdameefcgfflemilobdpg",
  DESKTOP: "",
};

const CONTACTS = {
  supportVPN4u: "support@vpn4u.io",
  supportQTech: "support@qtech.com",
  address: "560 W 43rd 41B, New York, NY USA 10036",
  phone: "+1 (646) 980 42 33",
};

export {
  API_URL,
  BLOCKFROST_API,
  BLOCKFROST_PROJECT_ID,
  INNER_WALLET_CRYPTO_PAYMENTS,
  DOWNLOAD_LINKS,
  CONTACTS,
};
