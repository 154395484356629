import React from "react";

import { copyToClipboard } from "../../../utils";

const ReferralView = ({
  referralInfo,
  referralLink,
  lockedBalance,
  formattedDate,
  daysUntil,
}) => (
  <div className="profile-referral">
    <h2>Earn passive income with a referral programme</h2>
    <div className="profile-referral__box">
      <h2 className="mb-10">What you earn:</h2>
      <div className="profile-referral__cards">
        <div className="profile-referral__card">
          <p>
            Your referral's first
            <br /> purchase
          </p>
          <h1>{referralInfo.first_purchase_rate}%</h1>
        </div>
        <div className="profile-referral__card">
          <p>
            Your referral's second
            <br /> purchase
          </p>
          <h1>{referralInfo.second_purchase_rate}%</h1>
        </div>
        <div className="profile-referral__card">
          <p>
            Your referral's third and
            <br /> subsequent purchases
          </p>
          <h1>{referralInfo.constant_purchase_rate}%</h1>
        </div>
      </div>
      <div className="profile-referral__item">
        <p className="mb-10">
          Share your referral link and referral code with your friends and
          community!{" "}
        </p>
        <p className="mb-10">
          Your unique referral link:{" "}
          <span className="profile-referral__link">{referralLink}</span>
          <button
            className="default-button alt profile-referral__btn"
            onClick={() =>
              copyToClipboard(
                referralLink,
                "Referral link successfully copied!"
              )
            }
          >
            Copy
          </button>
        </p>
        <p>
          Your unique referral code:{" "}
          <span className="profile-referral__link">{referralInfo.code}</span>
          <button
            className="default-button alt profile-referral__btn"
            onClick={() =>
              copyToClipboard(
                referralInfo.code,
                "Referral code successfully copied!"
              )
            }
          >
            Copy
          </button>
        </p>
      </div>
      <p className="profile-referral__banners">
        Use your own approach or <br />
        <a href="/referral-banners">pre-prepared materials</a>
        <br /> to attract referrals
      </p>
      <div className="profile-referral__item">
        <h3>Your statistic</h3>
        <div className="profile-referral__stats">
          <p>
            The number of your referrals:{" "}
            <span>{referralInfo.count_referrals}</span>
          </p>
          <p>
            Total income from referrals:{" "}
            <span>
              {Number(referralInfo.total_income_from_referrals).toFixed(2)} USDT
            </span>
          </p>
        </div>
      </div>

      {lockedBalance?.length ? (
        <>
          <p className="profile-referral__table-lbl">
            Funds from the referral program
          </p>
          <div className="profile-referral__table">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Created at</th>
                  <th>Available</th>
                </tr>
              </thead>
              <tbody>
                {lockedBalance.map((item, i) => (
                  <tr key={item.id}>
                    <td>{i + 1}</td>
                    <td>
                      {item.amount}{" "}
                      {item.currency === "usd" ? "$" : item.currency}
                    </td>
                    <td>{formattedDate(item.created_at)}</td>
                    <td>
                      {new Date() >= new Date(item.available_at)
                        ? "✔"
                        : `in ${daysUntil(item.available_at)} days`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  </div>
);

export default ReferralView;
