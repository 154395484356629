import "./index.scss";

import React from "react";

import BaseModal from "../../ui/BaseModal";
import Button from "../../ui/Button";

import { useScript } from "../../../hooks/useScripts";

import { SCRIPT_TYPES } from "../../../const/general";

const SuccessPaymentPopup = ({ close }) => {
  const device = localStorage.getItem("device");

  const redirectLink =
    device === "android"
      ? "/open-android-app"
      : device === "ios"
      ? "vpn4u.io://"
      : "/";

  useScript({ scriptType: SCRIPT_TYPES.googleTagSuccessPayment });

  return (
    <BaseModal close={close}>
      <div className="success-pay-na">
        <div className="success-pay-na__form">
          <h2>Congratulations!</h2>
          <p className="success-pay-na__title-lbl">
            Subscription was successful
          </p>
          <p className="success-pay-na__desc">
            Now you can go back to the app and use the VPN
          </p>

          <a href={redirectLink}>
            <Button mode="secondary" fullWidth>
              Back to the VPN4U app
            </Button>
          </a>
        </div>
      </div>
    </BaseModal>
  );
};

export default SuccessPaymentPopup;
