import "./index.scss";

import React from "react";

import BurgerBtn from "../../ui/BurgerButton";

const HeaderView = ({
  isHomePage,
  navigate,
  profileBtnTitle,
  handleClickProfileBtn,
  handleClickGetStarted,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}) => (
  <header className="header">
    <div className="header_container">
      <div className="header__logo" onClick={() => navigate("/")}>
        <a href="/">
          <img src="/images/logo-vpn4u.svg" alt="logo" />
        </a>
      </div>
      <nav style={isHomePage ? { marginLeft: "auto" } : {}}>
        <ul className="navList">
          <li>
            <a href="/#about">About VPN4U</a>
          </li>
          <li>
            <a href="/#prices">Prices</a>
          </li>
          <li>
            <a href="/#download">Download VPN</a>
          </li>
          <li>
            <a href="/#reviews">Reviews</a>
          </li>
          <li>
            <a href="/#referral">Referral</a>
          </li>
          {/*
            <li>
              <a href="/#faq">FAQ</a>
            </li>
            */}
        </ul>
      </nav>
      {isHomePage && (
        <button
          className="secondary-button get-started"
          style={{ marginLeft: "auto", marginRight: "20px" }}
          onClick={handleClickGetStarted}
        >
          Get started
        </button>
      )}
      <button className="default-button alt" onClick={handleClickProfileBtn}>
        {profileBtnTitle}
      </button>

      <BurgerBtn
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
    </div>
  </header>
);

export default HeaderView;
